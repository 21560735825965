export const FlowEvents = {
  LEAD_CREATED: 'lead created',
  LEAD_UPDATED: 'lead updated',
  CONTRACT_CREATED: 'contract created',
  CONTRACT_SIGNED: 'contract signed',
  IS_WEBVIEW: 'webview detected',
} as const

export const SignatureEvents = {
  OWNSHERSHIP_CHOOSEN: 'user intent - ownership selected',
  DEAL_LOADING_VIEW: 'server event - deal loading view',
  DEAL_SIGNATURE_VIEW: 'server event - deal signature view',
  DEAL_MISSING_EMBED_URL: 'server event - deal missing embed url',
  EVALUATE_DEAL_PARAMETERS: 'client event - evaluate deal parameters',
  EVALUATE_DEAL_CREATING_LEAD: 'server event - evaluate deal - creating lead',
  EVALUATE_DEAL_UPDATING_LEAD: 'server event - evaluate deal - updating lead',
  EVALUATE_DEAL_CREATING_DEAL: 'server event - evaluate deal - creating deal',
  EVALUATE_DEAL_REQUESTING_EMBED:
    'server event - evaluate deal - requesting contract embed',
} as const

export const DocumentCollectingEvents = {
  USER_INTENT_CONFIRM_IDENTITY: 'user intent - confirm identity',
  USER_INTENT_SKIP_IDENTITY: 'user intent - skip identity',
  USER_INTENT_DOCUMENT_TYPE: {
    RG: 'user intent - document type - rg',
    CNH: 'user intent - document type - cnh',
    CNH_DIGITAL: 'user intent - document type - cnh digital',
  },
  SERVER_EVENT_DOCUMENT_VERSION: 'server event - document version',
  SERVER_EVENT_WILL_SKIP_BILL: {
    YES: 'server event - will skip bill - yes',
    NO: 'server event - will skip bill - no',
  },
  USER_INTENT_CAPTURE_OPTIONS: {
    CAMERA: 'user intent - capture options - camera',
    UPLOAD: 'user intent - capture options - upload',
  },
  USER_INTENT_BACK_TO_FRONT_UPLOAD: 'user intent - back to front upload',
  CLIENT_EVENT_DOCUMENT_COLLECTED: 'client event - documents collected',
  CLIENT_EVENT_SDK_SCRIPT_ERROR: {
    RETRY: 'client event - sdk script error - retry',
    FALLBACK: 'client event - sdk script error - fallback',
  },
  USER_INTENT_TRY_AGAIN: 'user intent - try again',
  CLIENT_EVENT_ALL_DOCUMENTS_COLLECTED:
    'client event - all documents collected',
} as const

export const DocumentDetectorHookEvents = {
  CLIENT_EVENT_SDK_SETUP: {
    SUCCESS: 'client event - sdk setup - success',
    FAILURE: 'client event - sdk setup - failure',
  },
  CLIENT_EVENT_CAMERA_PERMISSION: {
    GRANTED: 'client event - camera permission - granted',
    DENIED: 'client event - camera permission - denied',
  },
  CLIENT_EVENT_SDK_INITIALIZATION: {
    SUCCESS: 'client event - sdk initialization - success',
    FAILURE: 'client event - sdk initialization - failure',
  },
  CLIENT_EVENT_CAPTURE_RESULT: 'client event - capture result',
  CLIENT_EVENT_SDK_SHUTDOWN: 'client event - sdk shutdown',
}

export const UserCommonEvents = {
  USER_NAVIGATION_NATIVE_BACK: 'user navigation - back (native)',
  USER_NAVIGATION_REMIX_BACK: 'user navigation - back (remix)',
} as const
